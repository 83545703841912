<script setup></script>

<template>
  <div class="not-found">
    <div>404</div>
    <div>Not found</div>
  </div>
</template>

<style scoped lang="scss">
.not-found {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    font-size: 60px;
    font-weight: 600;
  }
}
</style>
