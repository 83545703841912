export default {
  langType: 'en_us',
  productName: 'Seer',
  slogan: 'Insight into life and know your fate',
  more: 'MORE INFORMATION',
  care: 'Follow us',
  contact: 'Contact us',
  copy: 'Copy Success',
  agreement: 'Service Agreement',
  policy: 'Privacy Policy',
  company: 'company：SEER ONNET PTE. LTD.',
};
