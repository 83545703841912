import 'normalize.css';
import '@/assets/js/rem';
import 'ant-design-vue/dist/reset.css';

import { createApp } from 'vue';

import App from '@/App.vue';
import router from '@/router';
import i18n from '@/locales/index.js';

const app = createApp(App);

app.use(router);
app.use(i18n);

app.mount('#app');
