export default {
  langType: 'zh_cn',
  langName: '简体中文',
  productName: '先知命局',
  slogan: '先知命局洞悉人生，先人一步知命用命',
  more: '了解更多',
  care: '关注我们',
  contact: '联系我们',
  copy: '复制成功',
  agreement: '服务协议',
  policy: '隐私政策',
  company: '公司：SEER ONNET PTE. LTD.',
};
