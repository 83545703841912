// 为了方便换算
const resize = () => {
  const html = document.documentElement;
  const wW = html.clientWidth; // 窗口宽度
  if (wW <= 768) {
    // 移动端
    const rem = (wW / 768) * 100;
    html.style.fontSize = `${rem}px`;
  } else {
    html.style.fontSize = '80px';
  }
};
resize();
window.addEventListener('resize', resize, false);
