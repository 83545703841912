export default {
  langType: 'zh_tw',
  langName: '簡體中文',
  productName: '先知命局',
  slogan: '先知命局洞悉人生，先人一步知命用命',
  more: '瞭解更多',
  care: '關注我們',
  contact: '聯繫我們',
  copy: '複製成功',
  agreement: '服務協議',
  policy: '隱私政策',
  company: '公司：SEER ONNET PTE. LTD.',
};
