import { createI18n } from 'vue-i18n';
import { Language } from '@local-utils/const';
import zh_tw from '@/locales/lang/zh_tw.js';
import zh_cn from '@/locales/lang/zh_cn.js';
import en_us from '@/locales/lang/en_us.js';

const i18n = createI18n({
  locale: Language.ZH_CN,
  fallbackLocale: Language.ZH_CN,
  legacy: false,
  messages: {
    [Language.ZH_TW]: zh_tw,
    [Language.ZH_CN]: zh_cn,
    [Language.EN_US]: en_us,
  },
});

export default i18n;
